import { MasterBtn } from 'atoms/buttons'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ihpl from 'assets/ihpl.svg'
import iitpl from 'assets/iitpl.svg'
import bgb from 'assets/our-investors.jpeg'

export default function OurInvestors() {
  const navigate = useNavigate()

  const data = [
    {
      icon: ihpl,
      title: 'IHPL Secured Creditors',
      link: '/merger/IHPL Secured Creditors.pdf',
    },
    {
      icon: ihpl,
      title: 'IHPL Unsecured Trade Creditors',
      link: '/merger/IHPL Unsecured Trade Creditors.pdf',
    },
    {
      icon: iitpl,
      title: 'IITPL Secured Creditors',
      link: '/merger/IITPL Secured Creditors.pdf',
    },
    {
      icon: iitpl,
      title: 'IITPL Unsecured Trade Creditors',
      link: '/merger/IITPL Unsecured Trade Creditors.pdf',
    },
  ]

  const downloadBrochure = (
    fileLink: string | undefined,
    fileName: string | undefined,
  ) => {
    if (!fileLink) {
      return
    }
    // console.log(fileLink, fileName)
    const downloadLink = document.createElement('a')
    downloadLink.href = fileLink
    downloadLink.target = '_blank'
    // downloadLink.download = fileName || ''
    downloadLink.click()
  }

  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  return (
    <>
      <section className="home-banner-main overflow-hidden h-screen  relative md:min-h-[700px] min-h-[400px] max-h-[600px] lg:max-h-[700px] xl:max-h-[none]">
        {/* <section className="home-banner-main overflow-hidden blade-top-padding-lg blade-top-margin relative xl:max-h-[none]"> */}
        <section
          style={{
            backgroundImage: `url(${bgb})`,
          }}
          // style={{
          //   backgroundImage: `url(https://api.innvolution.com/media/home/banners/banner-1720706505500-302734932banner-2.webp)`,
          // }}
          className={`sm:bg-[0px_-100px] bg-cover md:bg-[60%_0%] lg:bg-center bg-right bg-no-repeat h-full relative overflow-hidden flex flex-col justify-center`}
        >
          <div className="flex items-center flex-col-reverse md:flex-row min-[1800px]:gap-28">
            <div className="w-container pb-12 md:max-w-[19rem] mx-auto lg:max-w-sm xl:max-w-md 2xl:max-w-[30rem] md:ml-6 lg:ml-[10%] xsl:ml-[12%] 2xl:ml-[15%]">
              {/* <div className="w-container pb-12 text-center mx-auto"> */}
              <h2 className="text-appear-anim-delayed font-medium mt-2 xl:mt-3 w-full">
                Investors
              </h2>
            </div>
          </div>
        </section>
      </section>

      <section className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 w-container px-4 blade-top-padding-lg blade-bottom-padding-lg w-container gap-5 flex-wrap">
        {data.map((elem, index) => {
          return (
            <ActionCard
              target=""
              targetText="Read More"
              isNav={false}
              title={elem.title}
              onClick={() => {
                downloadBrochure(elem.link, elem.title)
              }}
              icon={elem.icon}
            />
          )
        })}
      </section>
    </>
  )
}

type IProps = {
  title: string
  target: string
  onClick: () => void
  isNav: boolean
  icon?: string
  targetText: string
}

function ActionCard<T extends boolean>({
  title,
  icon,
  isNav,
  target,

  targetText,
  onClick,
}: IProps) {
  const navigate = useNavigate()

  return (
    <article
      // onClick={() => {
      //   onClick()
      // }}
      className=" bg-white shadow-sm shadow-orange md:odd:last-of-type:col-span-2 xl:odd:last-of-type:col-span-1 cursor-pointer rounded-[5px] p-5 md:p-6 lg:p-8 xl:px-6 xl:gap-y-5 flex items-start gap-3 flex-col justify-between max-w-md mx-auto w-full hover:scale-[1.02] transition-all duration-300 border-2 border-transparent hover:border-orange"
    >
      <div>
        <div className="gap-4 ">
          <img src={icon} alt={title} className="w-auto h-10 text-left" />
        </div>
        <h5 className="font-medium mt-2">{title}</h5>
      </div>
      <div className="h-auto">
        <button
          aria-label={title}
          type="button"
          onClick={() => {
            onClick()
          }}
          className="outline-none gap-2 hover:stroke-orange fill-black focus-visible:stroke-orange group focus:text-orange hover:text-orange font-medium text-base underline decoration-from-font underline-offset-4  transition-all duration-300 ease-in-out flex items-center flex-0 rounded-full py-2"
        >
          {targetText}
        </button>
      </div>
    </article>
  )
}
