import './index.css'

import Pointers from './pointers'
import Banner from './banner'
import InformedUsPro from './informedUsAgile'
import StatsPro from './stats'
import CapacityCarouselPro from './carousel'
import TableFeaturesPro from './tableFeatures'
import SubFooter from 'organisms/subFooter'

import useFetchMail from 'hooks/useFetchMail'
import useGSAP from 'hooks/useGSAP'

export default function PinnacePro() {
  useFetchMail()
  useGSAP('.pro-master')

  return (
    <main className="pro-master pinnacle-pro-wrapper">
      <Banner />
      <Pointers />
      <StatsPro />
      <CapacityCarouselPro />
      <TableFeaturesPro />
      <InformedUsPro />
      <SubFooter />
    </main>
  )
}
