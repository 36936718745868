import Banner from './banner'
import SalientFeatures from './salientFeatrues'
import FeatureGridElite from './featureGrid'
import DesignFeatures from './designFeatures'
import UserExperience from './userExperience'

import './premierElite.css'
import AwardsRecognition from 'organisms/awardsRecognition'

import InformedUsElite from './informedUs'
import SubFooter from 'organisms/subFooter'
import Gallery from './enhancements'
import CapacityCarouselElite from './carousel'
import useFetchElite from 'hooks/useFetchElite'
import useFetchMail from 'hooks/useFetchMail'
import useGSAP from 'hooks/useGSAP'

export default function PremierElite() {
  useFetchElite()
  useFetchMail()
  useGSAP('.premier-master')

  return (
    <main className="premier-master premier-elite-wrapper">
      <Banner />
      <AwardsRecognition />
      <SalientFeatures />
      <FeatureGridElite />
      <CapacityCarouselElite />
      <DesignFeatures />
      <UserExperience />
      <Gallery />
      <InformedUsElite />
      <SubFooter />
    </main>
  )
}
