import Banner from './banner'

import AwardsRecognition from 'organisms/awardsRecognition'
import Pointers from './pointers'
import SubFooter from 'organisms/subFooter'
import './index.css'
import TableFeaturesAgile from './tableFeatures'
import UserExperience from './userExperience'
import InformedUsAgile from './informedUsAgile'
import CapacityCarouselAgile from './carousel'
import StatsAgile from './stats'
import Versatility from './versitality'
import useFetchAgile from 'hooks/useFetchAgile'
import useFetchMail from 'hooks/useFetchMail'
import useGSAP from 'hooks/useGSAP'

const pinnacleAgile = () => {
  useFetchAgile()
  useFetchMail()
  useGSAP('.agile-master')

  return (
    <main className="agile-master">
      <Banner />
      <AwardsRecognition />
      <Pointers />
      <Versatility />
      <StatsAgile />
      <CapacityCarouselAgile />
      <UserExperience />
      <TableFeaturesAgile />
      <InformedUsAgile />
      <SubFooter />
    </main>
  )
}

export default pinnacleAgile
