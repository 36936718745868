import React, { useState } from 'react'
import cardIcon from 'assets/globals/informedUsIcons/iitpl icon elite page 0.svg'
import cardIconA from 'assets/globals/informedUsIcons/iitpl icon elite page 1.svg'
import cardIconB from 'assets/globals/informedUsIcons/iitpl icon elite page 2.svg'
import cardIconC from 'assets/globals/informedUsIcons/iitpl icon elite page 3.svg'
import SpecificationModal from './speficationModal'
import FeatureCard from 'molecules/featureCard'
import { useNavigate } from 'react-router-dom'

export type FeatureCardsList = {
  title: string
  desc: string
}

export type actionCardsList = {
  showReadMore: boolean
  showDownload: boolean
  showContact: boolean
  showFindMore: boolean
}

type InformedProps = {
  title: JSX.Element
  columnClasses: string
  actionCardsList: actionCardsList
  featureCardsList: FeatureCardsList[]
  bgGrad?: string
  productTarget: string
  fileLink?: string
  fileName?: string
  productsIcon?: string
}

export default function InformedUs({
  title,
  columnClasses,
  actionCardsList,
  featureCardsList,
  bgGrad,
  fileLink,
  fileName,
  productsIcon,
  productTarget,
}: InformedProps) {
  const [isModal, setModal] = useState(false)

  const viewSpecifications = () => {
    setModal(true)
  }

  const downloadBrochure = (
    fileLink: string | undefined,
    fileName: string | undefined,
  ) => {
    if (!fileLink) {
      return
    }
    // console.log(fileLink, fileName)

    const downloadLink = document.createElement('a')
    downloadLink.href = fileLink
    downloadLink.download = fileName || ''

    downloadLink.click()
  }

  return (
    <div
      style={{
        background: bgGrad ? bgGrad : '#EDEDED',
      }}
    >
      <section
        className={` pt-3 lg:pt-6 md:pb-4 contact-info-wrappe bg-cover bg-center`}
      >
        <section className="blade-top-padding blade-bottom-padding-lg">
          <div className="blade-bottom-padding px-3 sm:w-container-sm">
            <div className="text-center px-3 ">
              {/* <h2 className="font-medium lg:hidden text-center md:-mt-2">
                {title}
              </h2> */}
              <h3
                className={`${
                  bgGrad ? 'text-white' : 'text-black'
                } gsap-opacity-trans-appear font-medium hidde lg:block text-center leading-tight md:-mt-2 2xl:-mt-3"`}
              >
                {title}
              </h3>
            </div>
          </div>
          <div
            className={`w-container-lg grid md:grid-cols-2 gap-5 xl:gap-7 2xl:gap-10 ${columnClasses}`}
          >
            {actionCardsList.showReadMore && (
              <ActionCard
                targetText="Read More"
                isNav={false}
                target=""
                title="Technical specifications"
                onClick={viewSpecifications}
                icon={cardIcon}
              />
            )}

            {actionCardsList.showDownload && (
              <ActionCard
                target=""
                targetText="Download"
                isNav={false}
                title="Download brochure"
                onClick={() => {
                  downloadBrochure(fileLink, fileName)
                }}
                icon={cardIconA}
              />
            )}

            {actionCardsList.showContact && (
              <ActionCard
                targetText="Contact Us"
                isNav={true}
                target="/contact-us"
                title="Contact & support"
                onClick={() => {}}
                icon={cardIconC}
              />
            )}

            {actionCardsList.showFindMore && (
              <ActionCard
                isNav={true}
                target={productTarget}
                title="Find more products like this"
                targetText="Know More"
                onClick={() => {}}
                icon={productsIcon ? productsIcon : cardIconB}
              />
            )}
          </div>
        </section>
      </section>
      <SpecificationModal
        handleModalClose={() => setModal(false)}
        isActive={isModal}
        title="specification modal"
      >
        <div className="!px-0 md:px-4 pt-2 pb-5 overflow-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-10">
            {featureCardsList.map((card, index) => {
              return (
                <FeatureCard title={card.title} desc={card.desc} key={index} />
              )
            })}
          </div>
        </div>
      </SpecificationModal>
    </div>
  )
}

type IProps = {
  title: string
  target: string
  onClick: () => void
  isNav: boolean
  icon: string
  targetText: string
}

function ActionCard<T extends boolean>({
  title,
  icon,
  isNav,
  target,
  targetText,
  onClick,
}: IProps) {
  const navigate = useNavigate()

  return (
    <article
      onClick={() => {
        isNav && navigate(target)
        onClick()
      }}
      className="bg-white md:odd:last-of-type:col-span-2 xl:odd:last-of-type:col-span-1 cursor-pointer rounded-[5px] p-5 md:p-6 lg:p-8 xl:px-6 xl:gap-y-5 flex items-start gap-3 flex-col justify-between max-w-md xl:justify-start mx-auto w-full hover:scale-105 transition-all duration-300 border-2 border-transparent hover:border-orange"
    >
      <div className="grid place-content-center place-items-center gap-2">
        <img src={icon} alt={title} className="w-auto h-12" />
      </div>
      <h5 className="font-medium xl:text-">{title}</h5>
      {/* <div>
        {isNav ? (
          <Link
            className="outline-none gap-2 hover:stroke-orange fill-black focus-visible:stroke-orange group focus:text-orange hover:text-orange font-medium text-base underline decoration-from-font underline-offset-4  transition-all duration-300 ease-in-out flex items-center flex-0 rounded-full py-2  mr-auto "
            to={target}
            aria-label={targetText}
            // target="_self"
            rel="noreferrer"
          >
            {targetText}
          </Link>
        ) : (
          <button
            aria-label={title}
            type="button"
            onClick={onClick}
            className="outline-none gap-2 hover:stroke-orange fill-black focus-visible:stroke-orange group focus:text-orange hover:text-orange font-medium text-base underline decoration-from-font underline-offset-4  transition-all duration-300 ease-in-out flex items-center flex-0 rounded-full py-2"
          >
            {targetText}
          </button>
        )}
      </div> */}
    </article>
  )
}
